<template>
  <div>
    <ServerError v-if="ServerError" /> <PageLoader :storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: sofiaProSemibold;
                font-size: 18px;
              "
              >Do you want to delete this {{ dataType }} ?</span
            >
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="deleteData"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="deleteDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: sofiaProSemibold;
                font-size: 18px;
              "
              >Upload Icon</span
            >
          </v-flex>
          <v-flex xs12 pt-3>
            <v-layout wrap justify-center pa-5 style="border: solid 1px">
              <v-flex xs12 text-left>
                <v-chip
                  v-if="panCard.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span>{{ panCard.name }} </span>
                    </v-flex>
                  </v-layout>
                </v-chip>
                <v-flex xs12 text-right>
                  <v-btn
                    color="primary"
                    dark
                    small
                    @click="$refs.panCard.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                        color: white;
                      "
                    >
                      UPLOAD ICON
                    </span></v-btn
                  >
                </v-flex>
                <input
                  v-show="false"
                  id="file"
                  ref="panCard"
                  type="file"
                  accept=".svg, .png"
                  @change="panCardUpload($event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="uploadIcon()"
              class="itemValue"
            >
              Upload
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="uploadDialogue = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card outlined>
          <v-layout wrap justify-center py-2>
            <v-flex xs12 sm10>
              <v-layout wrap justify-start>
                <v-flex xs12 sm4 md4>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Name</span>
                  </v-flex>
                  <v-flex xs12 sm12 text-left>
                    <span class="itemValue">
                      {{ storage.name }}
                      <span v-if="!storage.name">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex align-self-center xs12 sm2 md2 px-2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="green"
                    :ripple="false"
                    @click="
                      $router.push({
                        path: '/Admin/dynamicData/animalAge',
                        query: {
                          id: storage._id,
                          name: storage.name,
                        },
                      })
                    "
                    class="itemValue"
                  >
                    <span style="color: green"> Add Age </span>
                  </v-btn>
                </v-flex>
                <v-flex align-self-center xs12 sm2 md2 px-2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="blue"
                    :ripple="false"
                    @click="uploadDialogue = true"
                    class="itemValue"
                  >
                    <span style="color: blue">Upload Icon</span>
                  </v-btn>
                </v-flex>
                <v-flex align-self-center xs12 sm2 md2 px-2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#FF1313"
                    :ripple="false"
                    @click="editData"
                    class="itemValue"
                  >
                    <span style="color: #ff1313"> Edit </span>
                  </v-btn>
                </v-flex>
                <v-flex align-self-center xs12 sm2 md2 px-2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#FF1313"
                    :ripple="false"
                    @click="deleteDialogue = true"
                    class="itemValue"
                  >
                    <span style="color: #ff1313"> Delete </span>
                  </v-btn>
                </v-flex>
               
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import axios from "axios";
export default {
  props: ["storage", "deleteUrl", "dataType"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      timeout: 5000,
      showSnackBar: false,
      deleteDialogue: false,
      uploadDialogue: false,
      iconFile: null,
      panCard: "",
      name: "",
    };
  },
  methods: {
    editData() {
      this.$emit("stepper", {
        type: "Edit",
        editData: true,
        dynamicData: this.storage,
      });
    },
    panCardUpload(event) {
      this.panCard = event.target.files[0];
    },
    deleteData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: this.deleteUrl,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.msg = response.data.msg;
            this.showSnackBar = true;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.panCard = null;
            this.$emit("stepper", {
              type: "Delete",
              getData: true,
              pageResponse: response.data,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadIcon() {
      if (this.panCard) {
        const formData = new FormData();
        formData.append("icon", this.panCard);
        formData.append("id", this.storage._id);

        this.appLoading = true;
        axios({
          method: "POST",
          url: "/animal/upload/icon/",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
          .then((response) => {
            if (response.data.status) {
              this.appLoading = false;
              this.uploadDialogue = false;
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
  },
};
</script>
  